import React from 'react';
import Steps from './Steps';
import './Etapes.css';

const Etapes = () => {
  return (
    <div className="App">

      <main>
        <Steps />
      </main>
    </div>
  );
};

export default Etapes;
